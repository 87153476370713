<template>
  <v-container
    fluid
    class="d-flex flex-column flex-grow-1"
  >
    <v-row class="first-row overflow-y-hidden mt-0 overflow-hidden">
      <v-col
        cols=7
        class="full-height"
      >
        <work-items
          class="mr-0"
          :show-all="showAll"
        />
      </v-col>
      <v-col
        cols=5
        class="full-height py-0 mt-3"
      >
        <accounts-kpi
          class="ml-n2"
          :show-all="showAll"
        />
      </v-col>
    </v-row>
    <v-row class="second-row overflow-y-hidden">
      <v-col class="full-height">
        <disputes
          class="mr-0"
          :show-all="showAll"
        />
      </v-col>
      <v-col class="full-height">
        <promises
          class="ml-0"
          :show-all="showAll"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  components: {
    AccountsKpi: () => import('./accounts-kpi'),
    Disputes: () => import('./disputes'),
    Promises: () => import('./promises'),
    WorkItems: () => import('./work-items')
  },
  mounted () {
    this.setModule()
  },
  activated () {
    this.setModule()
  },
  methods: {
    setModule () {
      this.$store.commit('setModule', {
        name: this.$t('t.Dashboard'),
        icon: 'i.Dashboard'
      })
    }
  },
  props: {
    showAll: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.first-row
  flex 0 0 60%

.second-row
  flex 0 0 40%

.full-height
  height 100%

>>> .vh-related
  height 12vh
</style>
